import React from "react";
import LazyLoad from "react-lazyload";
import { graphql } from "gatsby";
import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import "./index.scss";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { DocumentStyle } from "~components/templates/document-style";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { Section, SubSection } from "~components/templates/section";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";
import { Interview } from "~components/utils/interview";

type Props = {
  data: GatsbyRiskFocusPageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "リスクフォーカス型プラン",
          description:
            "定められた期間内に、可能な限りビジネスリスクの高い脆弱性を探すセキュリティ診断の手法です。アプリの仕様などを鑑み、より深刻度の高い脆弱性がありそうな箇所から優先付けを行い診断を実施します。",
        }}
      >
        <div className="AssessmentCommonPage">
          <LazyLoad>
            <div className="AssessmentCommonTop RiskFocus_Top">
              <div className="AssessmentCommonTop_Wrapper">
                <Common2PaneSectionLayout>
                  <DocumentStyle width="no-limit">
                    <h1>リスクフォーカス型プラン</h1>
                    <p>
                      定められた期間内に、可能な限りビジネスリスクの高い脆弱性を探すセキュリティ診断の手法です。アプリの仕様などを鑑み、より深刻度の高い脆弱性がありそうな箇所から優先付けを行い診断を実施します。
                      <br />
                      診断項目は網羅的ではないものの、規模の大きい診断対象であっても全体を対象にリスクを洗い出すことができます。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </div>
            </div>
          </LazyLoad>

          <BreadCrumbs title="リスクフォーカス型プラン" />

          <Section background="subtle-gray">
            <Common2PaneSectionLayout>
              <DocumentStyle>
                <h2>
                  <span>セキュリティ診断実施における</span>
                  <span>一般的な課題</span>
                </h2>
                <p>
                  セキュリティ診断(脆弱性診断)において、理想はもちろん診断対象の全機能を対象にして、診断項目を網羅的に検証していくことです。
                  <br />
                  <br />
                  しかし、診断対象のアプリケーションが一定以上の規模になるとどうしても費用が高額になってしまいます。結果、全機能を対象にすることができなくなるケースが非常に多いです。
                </p>
              </DocumentStyle>
              <div className="RiskFocus_ImageWrapper">
                <img
                  className="RiskFocus_Image"
                  src={data?.image01?.publicURL || ""}
                  alt="機能毎に潜む脆弱性リスクの大小"
                />
                <img
                  className="RiskFocus_Image"
                  src={data?.image02?.publicURL || ""}
                  alt="理想は全体を診断する事ですが、それには高額な予算が必要です"
                />
              </div>
              <DocumentStyle>
                <p>
                  通常そのようなケースにおいて、ご予算に応じた診断プラン策定のために採用される手法は、診断対象となる機能を絞り込む「スコープ」です。
                  <br />
                  <br />
                  しかし、スコープはあくまで対象の機能を絞り込むものであり、診断項目(有無を検証する脆弱性)は絞り込みません。これは網羅的な調査を保証する上で大きなメリットがありますが、時にビジネス上の優先度が低い脆弱性の調査にまで工数を割いているという形にもなり得ます。
                </p>
              </DocumentStyle>
              <div className="RiskFocus_ImageWrapper">
                <img
                  className="RiskFocus_Image"
                  src={data?.image03?.publicURL || ""}
                  alt="予算が足りない時、一般的に取られる手法は診断対象の機能を絞り込む「スコープ」です。"
                />
                <img
                  className="RiskFocus_Image"
                  src={data?.image04?.publicURL || ""}
                  alt="セキュリティ診断では原則網羅的に診断項目を検証するため、ビジネス上は優先度の低い脆弱性に工数が割かれることがあります。"
                />
              </div>
              <DocumentStyle>
                <p>
                  リスクフォーカス型プランでは、以下をあえて限定せずに診断を開始します。
                </p>
                <ul>
                  <li>診断対象の機能</li>
                  <li>検証する診断項目</li>
                </ul>
                <p>
                  セキュリティエンジニアの動きに自由度を与えることで、全機能を対象にしながらビジネス上の優先度が高い「リスクが大きい
                  /
                  発生しやすく狙われやすい」にフォーカスすることができるのが本プランの特徴です。
                </p>
              </DocumentStyle>
              <div className="RiskFocus_ImageWrapper">
                <img
                  className="RiskFocus_Image"
                  src={data?.image05?.publicURL || ""}
                  alt="工数と料金は比例しています。高優先度の脆弱性に工数を割いて欲しいというニーズは存在するはずです。"
                />
                <img
                  className="RiskFocus_Image"
                  src={data?.image06?.publicURL || ""}
                  alt="事前に「スコープ」しない事でそのニーズを満たすのが「リスクフォーカスプラン」です。"
                />
              </div>
            </Common2PaneSectionLayout>
          </Section>

          <LazyLoad>
            <Section>
              <SubSection>
                <Common2PaneSectionLayout>
                  <DocumentStyle>
                    <h2>
                      <span>リスクフォーカス型</span>
                      <span>プランの概要</span>
                    </h2>
                    <p>
                      定められた期間内に、可能な限りビジネスリスクの高い脆弱性を調査します。アプリの仕様などを鑑み、より深刻度の高い脆弱性がありそうな箇所から優先付けを行い診断を実施します。
                      <br />
                      <br />
                      通常の診断のように「この範囲を検証したら終了」といった形式ではなく、3週間(15営業日)・4週間(20営業日)といった固定期間の中でベストエフォートで脆弱性を調査します。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </SubSection>
              <SubSection>
                <Common2PaneSectionLayout>
                  <DocumentStyle>
                    <h3>本プランのメリット</h3>
                    <ul>
                      <li>
                        深刻な脆弱性を見つける上で一般的な診断よりもコストパフォーマンス(時間・費用)が圧倒的に高く、短期間で結果を得ることで次のアクションを策定しやすくなる。
                      </li>
                      <li>
                        大規模な診断対象でも全体を対象に診断を行うことができる。
                      </li>
                    </ul>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </SubSection>
              <SubSection>
                <Common2PaneSectionLayout>
                  <DocumentStyle>
                    <h3>本プランのデメリット</h3>
                    <p>
                      本プランでは柔軟な実施体制を実現する一方で、通常の診断と比較した場合、以下の点がデメリットとなり得ます。以下のデメリットが要件にそぐわない場合は、通常の診断をおすすめいたします。お気軽にご相談ください。
                    </p>
                    <ul>
                      <li>
                        診断項目を網羅的にチェックするわけではないので、リスクが低い脆弱性は調査の対象外になりやすい。
                      </li>
                      <li>
                        検証範囲や検証内容に関して具体的なログが残らない
                        (通常は報告書に診断対象のエンドポイントと診断項目が記載されます)。
                      </li>
                      <li>
                        標準化された手順の診断ではないため、「診断員の技術力への信頼ベース」での依頼となる。
                      </li>
                    </ul>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </SubSection>
              <SubSection>
                <Common2PaneSectionLayout>
                  <DocumentStyle>
                    <h3>提供をご依頼するデータ</h3>
                    <p>
                      原則としてソースコードをご提供いただきます。
                      <br />
                      <br />
                      本プランは定められた時間のみ診断を行うものなので、診断の効率化は費用対効果に直結します。ソースコードをご提供いただくことで迅速に原因を特定できる脆弱性があったり、ある脆弱性と同様の原因で脆弱性が発生している箇所を幅広く容易に特定できたりします。
                      <br />
                      <br />
                      なお、「ホワイトボックス診断」として明示的にご発注いただくケースでなければ、ソースコードをくまなく読むわけではございません。あくまで「ブラックボックス診断」の中で参考にするという扱いであることをご了承ください。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </SubSection>
              <SubSection>
                <Common2PaneSectionLayout>
                  <DocumentStyle>
                    <h3>本プランのユースケース</h3>
                    <p>
                      大規模な診断対象に対して、予算や見積もり/診断実施にかかる期間を短縮したい場合に、以下のように各種セキュリティ診断メニューと組み合わせて利用可能です。
                    </p>
                    <ul>
                      <li>
                        <a href="/assessment/web_app">
                          Webアプリケーション診断
                        </a>
                      </li>
                      <li>
                        <a href="/assessment/cloud_platform">
                          AWS・Google Cloud・Azure診断
                        </a>
                      </li>
                      <li>
                        <a href="/assessment/firebase">Firebase診断</a>
                      </li>
                      <li>
                        <a href="/assessment/graphql">GraphQL診断</a>
                      </li>
                      <li>
                        <a href="/assessment/spa">SPA診断</a>
                      </li>
                    </ul>
                    <p>
                      プラットフォーム診断など組み合わせることができないメニューもございます。詳細はお気軽にご相談ください。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </SubSection>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Interview title="リスクフォーカス型プランの事例インタビュー">
              <AssessmentVoiceList
                slugs={[
                  "ubie",
                  "smarthr",
                  "onecareer",
                  "primenumber",
                  "smartround",
                  "kickflow",
                ]}
              />
            </Interview>
          </LazyLoad>

          <LazyLoad>
            <Section background="brand">
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>料金</h2>
                  <p>
                    診断対象の規模感やお客様のご予算に合わせて2週間(10営業日)から4週間(20営業日)程度まで診断期間をご提案します。料金は概ね診断期間の長さに比例します。
                    <br />
                    <br />
                    ※ご希望に応じてより長い期間で実施することも可能です。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <AssessmentDocContact />
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export const query = graphql`
  query RiskFocusPage {
    image01: file(relativePath: { eq: "assessment/risk_focus_01.png" }) {
      publicURL
    }
    image02: file(relativePath: { eq: "assessment/risk_focus_02.png" }) {
      publicURL
    }
    image03: file(relativePath: { eq: "assessment/risk_focus_03.png" }) {
      publicURL
    }
    image04: file(relativePath: { eq: "assessment/risk_focus_04.png" }) {
      publicURL
    }
    image05: file(relativePath: { eq: "assessment/risk_focus_05.png" }) {
      publicURL
    }
    image06: file(relativePath: { eq: "assessment/risk_focus_06.png" }) {
      publicURL
    }
  }
`;

export default withContext(Component);
